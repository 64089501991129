<template>
  <div class="page-banners-detail">
    <Loading v-if="loading" :count="6"></Loading>
    <el-descriptions v-else :column="1">
      <el-descriptions-item label="模块">
        <span v-if="detail.model == 1">首页</span>
        <span v-if="detail.model == 2">校地合作</span>
        <span v-if="detail.model == 3">校企合作</span>
      </el-descriptions-item>
      <el-descriptions-item label="tab">
        {{ detail.tab || '-' }}
      </el-descriptions-item>
      <el-descriptions-item label="标题">
        {{ detail.title || '-' }}
      </el-descriptions-item>
      <el-descriptions-item label="图片">
        <el-image
          style="width: 100px; height: 100px"
          :src="detail.imgUrl"
          :preview-src-list="[detail.imgUrl]"
        ></el-image>
      </el-descriptions-item>
      <el-descriptions-item label="跳转类型">
        <span v-if="detail.urlType === 1">公众号链接</span>
        <span v-if="detail.urlType === 2">内部文章</span>
      </el-descriptions-item>
      <el-descriptions-item label="跳转链接">
        {{ detail.url || '-' }}
      </el-descriptions-item>
    </el-descriptions>
  </div>
</template>

<script>
import Loading from '@/components/loading'

export default {
  components: {
    Loading
  },
  data() {
    return {
      loading: true,
      id: this.$route.params.id,
      detail: null
    }
  },
  mounted() {
    this.fetchDetail()
  },
  methods: {
    async fetchDetail() {
      this.loading = true
      const [err, res] = await this.$serve.mpBanner.info({
        queryId: this.id
      })
      this.loading = false
      if (err) return
      this.detail = res
    }
  }
}
</script>

<style lang="scss" scoped>
.page-banners-detail {
  ::v-deep {
    .el-descriptions-item__container {
      min-height: 40px;
      line-height: 40px;
    }
    .el-descriptions-item__label {
      width: 120px;
      text-align: right;
    }
  }
}
</style>
